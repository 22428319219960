<template>
  <div class="padd_content">
    <div class="d-flex pa-2 mb-3 align-center">
      <BlueCircle class="mr-2" />
      <h3 class="color_default">Akun</h3>
    </div>
    <v-card
      v-if="role == 'Administrator'"
      class="rounded-max custom_card pa-8 mb-5 mx-2"
    >
      <div class="d-flex align-center">
        <h4 class="color_default mr-5">Akses Login</h4>
        <div
          v-if="loginActive"
          class="switch_active d-flex"
          @click="switchStatusLogin(1)"
        >
          <div style="width: 70%" class="d-flex justify-center">
            <b>Aktif</b>
          </div>
          <div class="btn_switch">x</div>
        </div>
        <div v-if="!loginActive" class="switch_unactive d-flex">
          <div class="btn_switch">x</div>
          <div style="width: 70%" class="d-flex justify-center">
            <b>Non-Aktif</b>
          </div>
        </div>
      </div>
    </v-card>
    <v-tabs
      v-model="tab"
      background-color="#cdf0f7"
      class="px-2 pb-2"
      @change="onTabChange"
    >
      <v-tab
        v-for="(tab_item, i) in tabs"
        :key="i"
        :href="'#' + tab_item"
        class="text-capitalize mr-3 header_tab"
        active-class="active_tab"
      >
        <h3>{{ tab_item }}</h3>
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="tab"
      style="background: none"
      class="px-2"
      v-if="tab === `${ROLES.MinistryOfHealth}+${ROLES.External}`"
    >
      <v-tab-item v-for="(tab, i) in tabs" :key="i" :value="tab">
        <TableKemenkesEksternal
          :users.sync="users"
          :role.sync="ROLES.PublicHealthService_Province"
          :page.sync="page"
          :limit.sync="limit"
          :total="total"
          :find.sync="find"
          :has-prev="!!prev_page_url"
          :has-next="!!next_page_url"
          @refetch="getUsers"
        />
        <div style="height: 100px" />
      </v-tab-item>
    </v-tabs-items>

    <v-tabs-items v-model="tab" style="background: none" class="px-2" v-else>
      <v-tab-item v-for="(tab, i) in tabs" :key="i" :value="tab">
        <TableUser
          :users.sync="users"
          :role.sync="ROLES.PublicHealthService_Province"
          :page.sync="page"
          :limit.sync="limit"
          :total="total"
          :find.sync="find"
          :has-prev="!!prev_page_url"
          :has-next="!!next_page_url"
          @refetch="getUsers"
        />
        <div style="height: 100px" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BlueCircle from "../../../components/General/BlueCircle";
import TableUser from "../../../components/Pengguna/View/TableUser.vue";
import TableKemenkesEksternal from "../../../components/Pengguna/View/TableKemenkesEksternal.vue";
import {
  FASYANKES_TYPE,
  FASYANKES_TYPE_NAME,
  ROLES,
} from "../../../constants/constant";

export default {
  components: {
    BlueCircle,
    TableUser,
    TableKemenkesEksternal,
  },
  data() {
    return {
      ROLES,
      selected_province_id: "",
      users: [],
      page: 1,
      limit: 10,
      total: 0,
      total_page: 0,
      next_page_url: "",
      prev_page_url: "",
      role: "",
      roles: "",
      find: "",
      loading: false,
      tab: "",
      tabs: [
        ROLES.PublicHealthService_Province,
        ROLES.PublicHealthService_Regency,
        FASYANKES_TYPE_NAME[FASYANKES_TYPE.Hospital],
        FASYANKES_TYPE_NAME[FASYANKES_TYPE.PublicHealthCenter],
        `${ROLES.MinistryOfHealth}+${ROLES.External}`,
      ],
      updateLoginStatus: false,
      loginActive: true,
    };
  },
  computed: {
    ...mapState({
      admin_role: (state) => state.role,
      profile: (state) => state.user.profile,
    }),
  },
  watch: {
    find() {
      this.page = 1;
      this.getUsers();
    },
    limit() {
      this.page = 1;
      this.getUsers();
    },
    page() {
      this.getUsers();
    },
    tipe_fasyankes() {
      this.getUsers();
    },
    role() {
      if (this.role === FASYANKES_TYPE_NAME[FASYANKES_TYPE.Hospital]) {
        this.role = ROLES.HealthServiceFacility;
        this.tipe_fasyankes = FASYANKES_TYPE.Hospital;
      }

      if (
        this.role === FASYANKES_TYPE_NAME[FASYANKES_TYPE.PublicHealthCenter]
      ) {
        this.role = ROLES.HealthServiceFacility;
        this.tipe_fasyankes = FASYANKES_TYPE.PublicHealthCenter;
      }

      this.getUsers();
    },

    profile() {
      this.getUsers();
    },
  },
  mounted() {
    if (this.admin_role === ROLES.PublicHealthService_Province) {
      this.tabs.shift();
      this.tabs.pop();
    }
  },
  methods: {
    async getUsers() {
      this.loading = true;
      this.menu = false;
      let body;
      if (this.tab === FASYANKES_TYPE_NAME[FASYANKES_TYPE.Hospital]) {
        body = {
          role: this.role,
          find: this.find,
          limit: this.limit,
          page: this.page,
          tipe_fasyankes: "1",
        };
      } else if (
        this.tab === FASYANKES_TYPE_NAME[FASYANKES_TYPE.PublicHealthCenter]
      ) {
        body = {
          role: this.role,
          find: this.find,
          limit: this.limit,
          page: this.page,
          tipe_fasyankes: "2",
        };
      } else {
        body = {
          roles: this.roles,
          find: this.find,
          limit: this.limit,
          page: this.page,
          tipe_fasyankes: this.tipe_fasyankes,
        };
      }

      if (this.profile && this.profile.provinsi) {
        body.province_id = this.profile.provinsi;
      }

      const res = await this.$store.dispatch("user/filterAllinOne", { body });
      this.users = res.data;
      this.total = res.total;
      this.total_page = Math.ceil(res.total / this.limit);
      this.next_page_url = res.next_page_url;
      this.prev_page_url = res.prev_page_url;
      this.loading = false;
    },
    switchStatusLogin() {
      this.updateLoginStatus = true;
    },
    onTabChange() {
      if (this.tab === `${ROLES.MinistryOfHealth}+${ROLES.External}`) {
        this.roles = [ROLES.MinistryOfHealth, ROLES.External];
      } else if (this.tab === FASYANKES_TYPE_NAME[FASYANKES_TYPE.Hospital]) {
        this.role = ROLES.HealthServiceFacility;
        this.tipe_fasyankes = "1";
      } else if (
        this.tab === FASYANKES_TYPE_NAME[FASYANKES_TYPE.PublicHealthCenter]
      ) {
        this.role = ROLES.HealthServiceFacility;
        this.tipe_fasyankes = "2";
      } else {
        this.roles = [this.tab];
      }

      this.page = 1;
      this.limit = 10;
      this.find = "";
      this.tipe_fasyankes = "";

      this.getUsers();
    },
  },
};
</script>

<style scoped>
.padd_content {
  padding: 40px 50px;
}
</style>
