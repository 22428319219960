<template>
    <div>
      <v-card class="radius_intab custom_card pa-5">
        <div class="toolbar d-flex">
          <div style="width: 70%">
            <v-row>
              <v-col cols="5">
                <v-text-field
                  v-model="local_find"
                  flat
                  label="Cari Pengguna"
                  class="custom_field"
                  background-color="#F1FBFC"
                  solo
                  hide-details="auto"
                  prepend-inner-icon="mdi-magnify"
                  color="blue"
                  @keyup="search"
                />
              </v-col>
  
              <v-col cols="3">
                <v-menu
                  v-model="menuLimit"
                  bottom
                  :nudge-bottom="55"
                  elevation="0"
                >
                  <template #activator="{ on, attrs }">
                    <v-card
                      class="pa-3 d-flex align-center"
                      outlined
                      v-bind="attrs"
                      height="100%"
                      v-on="on"
                    >
                      <p class="ma-0 mr-2 color_txt small_txt">
                        Show : {{ limit }}
                      </p>
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-card>
                  </template>
                  <v-card
                    class="popup_radius"
                    outlined
                    elevation="0"
                  >
                    <v-list>
                      <v-list-item
                        link
                        @click="changeLimit(10)"
                      >
                        <v-list-item-title>10</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        link
                        @click="changeLimit(50)"
                      >
                        <v-list-item-title>50</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        link
                        @click="changeLimit(100)"
                      >
                        <v-list-item-title>100</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </v-col>
            </v-row>
          </div>
          <div
            class="d-flex justify-end align-center"
            style="width: 30%"
          >
            <v-btn
              depressed
              rounded
              dark
              color="#00b4cc"
              class="text-capitalize"
              @click="dialogCreate = true"
            >
              <p class="my-2">
                Tambah
              </p>
              <v-icon class="ml-2 ">
                mdi-account-plus
              </v-icon>
            </v-btn>
          </div>
        </div>
        <v-skeleton-loader
          v-if="loading"
          type="table-tbody"
        />
  
        <div
          v-if="users && !loading"
          class="mt-5"
        >
          <v-data-table
            :headers="headers"
            :items="users"
            :page.sync="local_page"
            :items-per-page="limit"
            class="elevation-0 color_txt"
            hide-default-footer
            :item-class="row_classes"
            :single-select="false"
          >
            <template #[`item.isActive`]="{ item }">
              <v-switch
                v-if="item.isActive"
                v-model="item.status"
                hide-details="auto"
                class="mt-0"
                inset
                @click.stop="changeStatus(item)"
              />
            </template>
            <template #[`item.status`]="{ item }">
              <p class="ma-0">
                {{ item.isActive == "NonActive" ? "Tidak Aktif" : "Aktif" }}
              </p>
            </template>
            <template #[`item.actions`]="{ item }">
              <div v-if="item">
                <v-btn
                  small
                  depressed
                  color="#00ccb6"
                  class="mr-1 action_btn"
                  min-width="10px"
                  dark
                  @click="viewUser(item)"
                >
                  <v-icon>mdi-eye-outline</v-icon>
                </v-btn>
                <v-btn
                  small
                  depressed
                  color="#00879a"
                  class="mr-1 px-1"
                  min-width="10px"
                  dark
                  @click="delUser(item)"
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
          <div class="d-flex justify-center pa-3">
            <div class="d-flex align-center pa-2 round_paginate">
              <v-btn
                small
                min-width="10px"
                color="#00b4cc"
                dark
                class="pa-2 custom_shadow"
                @click="pagination('prev')"
              >
                <v-icon small>
                  mdi-arrow-left
                </v-icon>
              </v-btn>
              <div class="mx-3">
                <p class="ma-0 color_default">
                  {{ page }} of {{ Math.ceil(total / limit) }}
                </p>
              </div>
              <v-btn
                small
                min-width="10px"
                color="#00b4cc"
                dark
                class="pa-2 custom_shadow"
                @click="pagination('next')"
              >
                <v-icon small>
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
      <div class="pa-2">
        <CreateUser
          :dialog-create="dialogCreate"
          @close="dialogCreate = false"
          @refetch="refetch()"
        />
        <UpdateUser
          ref="update"
          :dialog-update-user="dialogUpdateUser"
          :data-user="dataUser"
          @close="dialogUpdateUser = false"
          @refetch="refetch()"
          @pwd="showPwd"
        />
        <DeleteUser
          :dialog-delete-user="dialogDeleteUser"
          :data-user="dataUser"
          @refetch="refetch()"
          @close="dialogDeleteUser = false"
        />
        <ChangePassword
          :dialog-change-pwd="dialogChangePwd"
          :data-user="dataUser"
          @close="dialogChangePwd = false"
          @walrus="backFromChangePwd"
        />
        <ChangeStatusUser
          :dialog-change-status="dialogChangeStatus"
          :data-user="dataUser"
          @close="dialogChangeStatus = false"
          @refetch="refetch()"
        />
      </div>
    </div>
  </template>
    
  <script>
  import { ROLES } from "../../../constants/constant";
  // import DetailInstance from "../../dataFasyankes/detailInstance.vue";
  import UpdateUser from "..//Update/updateUser.vue";
  import CreateUser from "../Create/createUser.vue";
  import DeleteUser from "../Delete/deleteUser.vue";
  import ChangePassword from "../Update/changePassword.vue";
  import ChangeStatusUser from "../Update/changeStatusUser.vue";
  export default {
    name: "TableKemenkesEksternal",
      components: {
      CreateUser,
      DeleteUser,
      UpdateUser,
      ChangePassword,
      ChangeStatusUser,
      // DetailInstance,
    },
  
    props: {
      users: {
        type: Array,
        default: () => [],
      },
      role: {
        type: String,
        default: "",
      },
      page: {
        type: Number,
        default: 1,
      },
      limit: {
        type: Number,
        default: 10,
      },
      total: {
        type: Number,
        default: 0,
      },
      find: {
        type: String,
        default: "",
      },
      hasPrev: {
        type: Boolean,
        default: false,
      },
      hasNext: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        ROLES,
        timer_id: null,
        loading: false,
        dataUser: null,
        dataItem: null,
        tipe_fasyankes: "",
        access_right: "Dinkes Provinsi",
        menu: false,
        menuLimit: false,
        dialogDeleteUser: false,
        dialogUpdateUser: false,
        dialogChangePwd: false,
        dialogChangeStatus: false,
        dialogCreate: false,
        items: ["Foo", "Bar", "Fizz", "Buzz"],
        headers: [
          {
            text: "Jenis Akun",
            align: "start",
            value: "access_right",
          },
          {
            text: "Nama Pengguna",
            align: "start",
            value: "full_name",
          },
          {
            text: "No. Telepon",
            align: "start",
            value: "telpon",
          },
          { text: "Status", value: "status" },
          { text: "Aktivasi", sortable: false, value: "isActive" },
          { text: "Action", sortable: false, value: "actions" },
        ],
  
        // props
        local_users: this.users,
        local_role: this.role,
        local_page: this.page,
        local_limit: this.limit,
        local_total: this.total,
        local_find: this.find,
      };
    },
    methods: {
      refetch() {
        this.$emit("refetch");
      },
      search() {
        this.$emit("update:find", this.local_find);
      },
      changeLimit(limit) {
        this.$emit("update:limit", limit);
      },
      row_classes(item) {
        let idx = this.users.indexOf(item);
        if (idx % 2 == 0) {
          return "strip_blue";
        }
      },
      showPwd() {
        this.dialogUpdateUser = false;
        this.dialogChangePwd = true;
      },
      backFromChangePwd() {
        this.dialogChangePwd = false;
        this.dialogUpdateUser = true;
      },
      viewUser(item) {
        this.dataUser = item;
        this.dialogUpdateUser = true;
        this.$refs.update.getPerson(item.id);
      },
      changeStatus(item) {
        this.dataUser = item;
        this.dialogChangeStatus = true;
      },
      delUser(item) {
        console.log(item);
        this.dataUser = item;
        this.dialogDeleteUser = true;
      },
      pagination(state) {
        if (state == "next") {
          if (this.hasNext) {
            this.local_page++;
            this.$emit("update:page", this.local_page);
          }
        } else {
          if (this.hasPrev) {
            this.local_page--;
            this.$emit("update:page", this.local_page);
          }
        }
      },
    },
  };
  </script>
    
  <style scoped>
  * {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
  }
  
  .v-btn__content {
    min-width: none !important;
    width: 30px !important;
  }
  
  .round_paginate {
    border: 1px solid rgba(215, 215, 215, 1);
    border-radius: 10px;
    width: max-content;
  }
  </style> 